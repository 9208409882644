import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/scanFace',
    name: 'scanFace',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ScanFace" */ '../views/ScanFace.vue')
  },
  {
    path: '/scanList',
    name: 'scanList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ScanList" */ '../views/ScanList.vue')
  },
  {
    path: '/scanFace2',
    name: 'scanFace2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Scanface2" */ '../views/ScanFace2.vue')
  },
  {
    path: '/scanFace3',
    name: 'scanFace3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Scanface3" */ '../views/ScanFace3.vue')
  },
  {
    path: '/weight',
    name: 'weight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "weight" */ '../views/Weight.vue')
  },
  {
    path: '/dot',
    name: 'dot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dot" */ '../views/Dot.vue')
  },
  {
    path: '/dotList',
    name: 'dotList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dotList" */ '../views/DotList.vue')
  },
  {
    path: '/dotFinish',
    name: 'dotFinish',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dotFinish" */ '../views/DotFinish.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
